@import "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,700;1,400&family=Open+Sans:wght@400;500;600;700&family=Overpass:wght@400;700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  --random: #3b77f0;
}

.progress-bar {
  background-color: var(--random);
  transform-origin: 0%;
  height: 7px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

body {
  background-color: #ebebeb;
}

.mainpg-comp-content {
  height: 500px;
  width: 600px;
  flex-direction: column;
  justify-content: space-between;
  margin: 150px 200px;
  font-family: Open Sans;
  display: flex;
}

.greeting {
  font-size: 24px;
  font-weight: 600;
}

.intro {
  width: 430px;
  font-size: 24px;
  font-weight: 500;
}

.intro > span {
  color: #3b77f0;
  font-weight: 700;
}

.contact {
  font-weight: 500;
}

.contact > a {
  color: #000;
  border-bottom: 3px solid #3b77f0;
  text-decoration: none;
}

.background {
  gap: 40px;
  margin: 40vh 27vw 12vh;
  font-family: Open Sans;
  display: flex;
}

.bg-left {
  width: 150px;
}

.bg-left > p {
  color: #3b77f0;
  font-size: 17px;
  font-weight: 700;
}

.bg-right > p > span {
  font-weight: 700;
}

.bg-right {
  width: 600px;
  height: 260px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
  display: flex;
}

.bg-right > a {
  color: #000;
  background-color: #0000;
  border: none;
  border-bottom: 3px solid #3b77f0;
  align-self: start;
  font-weight: 700;
  text-decoration: none;
}

.skills {
  gap: 40px;
  margin: 10vh 27vw;
  font-family: Open Sans;
  display: flex;
}

.sk-left {
  width: 150px;
}

.sk-left > p {
  letter-spacing: 2px;
  color: #3b77f0;
  justify-content: end;
  font-size: 17px;
  font-weight: 700;
  display: flex;
}

.sk-right {
  width: 600px;
  height: 260px;
  flex-direction: right;
  flex-wrap: wrap;
  font-size: 15px;
  display: flex;
}

.single-img {
  width: 125px;
}

.tech-logo {
  height: 48px;
  width: 48px;
  transition: all .5s;
}

.tech-logo:hover {
  height: 54px;
  width: 54px;
  cursor: pointer;
}

.projects {
  gap: 40px;
  margin: 10vh 27vw;
  font-family: Open Sans;
  display: flex;
}

.pj-left {
  width: 150px;
}

.pj-left > p {
  letter-spacing: 2px;
  color: #3b77f0;
  justify-content: end;
  font-size: 17px;
  font-weight: 700;
  display: flex;
}

.pj-right {
  width: 600px;
  height: max-content;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  font-size: 15px;
  display: flex;
}

.pj-right > p {
  font-weight: 600;
}

.card {
  width: 520px;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.card-head {
  font-size: 17px;
  font-weight: 700;
}

.button-list {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.button-list > div {
  height: max-content;
  width: max-content;
  color: #3b77f0;
  background-color: #b8e8ff;
  border: 2px solid #3b77f0;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
}

.heading {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

.github {
  background-color: #0000;
  border: none;
}

.github > a {
  height: max-content;
  width: max-content;
  color: #3b77f0;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  text-decoration: none;
}

.live-project {
  background-color: #0000;
  border: none;
}

.live-project > a {
  height: max-content;
  width: max-content;
  color: #3b77f0;
  background-color: #0000;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-weight: 600;
  text-decoration: none;
}

.footer {
  justify-content: space-between;
  padding: 40px;
  font-family: Open Sans;
  display: flex;
}

.footer_left {
  font-size: 15px;
  font-weight: 600;
}

.footer_right {
  width: 300px;
  gap: 30px;
  font-weight: 600;
  display: flex;
}

.footer_right > a {
  width: 100px;
  height: 40px;
  color: #3b77f0;
  font-weight: 600;
  text-decoration: none;
}

.live_stats {
  gap: 40px;
  margin: 10vh 27vw;
  font-family: Open Sans;
  display: flex;
}

.live_stats_left {
  width: 150px;
}

.live_stats_left > p {
  letter-spacing: 2px;
  color: #3b77f0;
  justify-content: end;
  font-size: 17px;
  font-weight: 700;
  display: flex;
}

.live_stats_right {
  width: 600px;
  height: 200px;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  font-size: 15px;
  display: flex;
}

.live_stats_right > p > span {
  color: #000;
  font-weight: 700;
  transition: all .4s;
}

.live_stats_right > p > span:hover {
  border-bottom: 4px solid #1554d2;
}

.live_stats_right > p {
  color: #3b77f0;
  font-weight: 600;
}

.btn-view-more {
  color: #000;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-bottom: 3px solid #3b77f0;
  align-self: start;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
  .mainpg-comp-content {
    height: 500px;
    width: 367px;
    flex-direction: column;
    justify-content: space-between;
    margin: 50px 20px;
    font-family: Open Sans;
    display: flex;
  }

  .greeting {
    font-size: 24px;
    font-weight: 600;
  }

  .intro {
    width: 380px;
    font-size: 24px;
    font-weight: 500;
  }

  .intro > span {
    color: #3b77f0;
    font-weight: 700;
  }

  .contact {
    font-weight: 500;
  }

  .contact > a {
    color: #000;
    border-bottom: 3px solid #3b77f0;
    text-decoration: none;
  }

  .background {
    width: 367px;
    flex-direction: column;
    gap: 30px;
    margin: 40vh 20px;
    font-family: Open Sans;
    display: flex;
  }

  .bg-left {
    width: 150px;
  }

  .bg-left > p {
    color: #3b77f0;
    align-self: center;
    font-size: 17px;
    font-weight: 700;
  }

  .bg-right > p > span {
    font-weight: 700;
  }

  .bg-right {
    width: 80vw;
    height: 350px;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    font-size: 15px;
    display: flex;
  }

  .bg-right > a {
    color: #000;
    background-color: #0000;
    border: none;
    border-bottom: 3px solid #3b77f0;
    align-self: start;
    font-weight: 700;
  }

  .skills {
    width: 367px;
    flex-direction: column;
    gap: 80px;
    margin: 40vh 20px;
    font-family: Open Sans;
    display: flex;
  }

  .sk-left {
    width: 150px;
  }

  .sk-left > p {
    letter-spacing: 2px;
    color: #3b77f0;
    justify-content: start;
    font-size: 17px;
    font-weight: 700;
    display: flex;
  }

  .sk-right {
    width: 380px;
    height: 260px;
    flex-direction: right;
    flex-wrap: wrap;
    place-content: space-between center;
    align-self: center;
    font-size: 15px;
    display: flex;
  }

  .single-img {
    width: 115px;
    justify-content: center;
    display: flex;
  }

  .tech-logo {
    height: 44px;
    width: 44px;
    transition: all .5s;
  }

  .tech-logo:hover {
    height: 54px;
    width: 54px;
    cursor: pointer;
  }

  .projects {
    width: 367px;
    flex-direction: column;
    gap: 40px;
    margin: 40vh 20px;
    font-family: Open Sans;
    display: flex;
  }

  .pj-left {
    width: 150px;
  }

  .pj-left > p {
    letter-spacing: 2px;
    color: #3b77f0;
    justify-content: start;
    font-size: 17px;
    font-weight: 700;
    display: flex;
  }

  .pj-right {
    width: 367px;
    height: max-content;
    flex-direction: column;
    align-items: start;
    gap: 50px;
    font-size: 15px;
    display: flex;
  }

  .pj-right > p {
    font-weight: 600;
  }

  .card {
    width: 370px;
    flex-direction: column;
    gap: 10px;
    display: flex;
  }

  .card-head {
    font-size: 17px;
    font-weight: 700;
  }

  .button-list {
    flex-wrap: wrap;
    gap: 10px;
    display: flex;
  }

  .button-list > div {
    height: max-content;
    width: max-content;
    color: #3b77f0;
    background-color: #b8e8ff;
    border: 2px solid #3b77f0;
    border-radius: 5px;
    padding: 5px;
    font-weight: 600;
  }

  .heading {
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  .github {
    background-color: #0000;
    border: none;
  }

  .github > a {
    height: max-content;
    width: max-content;
    color: #3b77f0;
    background-color: #0000;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-weight: 600;
    text-decoration: none;
  }

  .live-project {
    background-color: #0000;
    border: none;
  }

  .live-project > a {
    height: max-content;
    width: max-content;
    color: #3b77f0;
    background-color: #0000;
    border: none;
    border-radius: 5px;
    padding: 5px;
    font-weight: 600;
    text-decoration: none;
  }

  .footer {
    width: 390px;
    gap: 10px;
    font-family: Open Sans;
    display: flex;
  }

  .footer_left {
    font-size: 15px;
    font-weight: 600;
  }

  .footer_right {
    gap: 8px;
    font-weight: 600;
    display: flex;
  }

  .footer_right > a {
    width: max-content;
    color: #3b77f0;
    font-weight: 600;
    text-decoration: none;
  }

  .live_stats {
    width: 367px;
    gap: 40px;
    margin: 40vh 20px;
    font-family: Open Sans;
    display: flex;
  }

  .live_stats_left {
    width: 150px;
  }

  .live_stats_left > p {
    letter-spacing: 2px;
    color: #3b77f0;
    justify-content: end;
    font-size: 17px;
    font-weight: 700;
    display: flex;
  }

  .live_stats_right {
    width: 600px;
    height: max-content;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    font-size: 15px;
    display: flex;
  }

  .live_stats_right > p > span {
    color: #000;
    font-weight: 700;
    transition: all .4s;
  }

  .live_stats_right > p > span:hover {
    border-bottom: 4px solid #1554d2;
  }

  .live_stats_right > p {
    color: #3b77f0;
    font-weight: 600;
  }
}

/*# sourceMappingURL=index.b9cc35f9.css.map */
